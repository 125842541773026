import { Application } from "@hotwired/stimulus"
import { registerControllers } from 'stimulus-vite-helpers'

const application = Application.start()
const controllers = import.meta.glob('./**/*_controller.js', { eager: true })
registerControllers(application, controllers)

function registerPhlexControllers() {
  const controllers = import.meta.glob(
    [
      "./../../views/**/controller.js",
    ],
    { eager: true }
  )

  for (let path in controllers) {
    let module = controllers[path]

    let name = path
      .match(/views\/(.+)\/controller\.js$/)[1]
      .replaceAll("_", "-")
      .replaceAll("/", "--")

    application.register(name, module.Controller)
  }
}

registerPhlexControllers()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }
